<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            All Users
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ totalUsers }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Active Users
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ activeUsers }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Inactive Users
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ inactiveUsers }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title class="primary--text text-h6 pb-0">
            Suspended Users
          </v-card-title>
          <v-card-text class="primary--text text-17 text-left">
            {{ suspendedUsers }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <users-table></users-table>
  </div>
</template>

<script>
import usersTable from "../../components/users/usersTable";
export default {
  name: "UsersPage",
  components: {
    usersTable,
  },
  data() {
    return {
      totalUsers: 0,
      activeUsers: 0,
      inactiveUsers: 0,
      suspendedUsers: 0,
    };
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style scoped></style>
